// Profile.js
import React from 'react';
import { FaFacebookF, FaYoutube, FaInstagram, FaWhatsapp } from 'react-icons/fa';

import doctorPhoto1 from './images/sachin sir.jpg';
import doctorPhoto2 from './images/sonal.jpg';
import './Profile.css';

function Profile() {
  return (
    <div className="profile-section">
      <div className="doctor-cards">
        <div className="logo-container"></div>
        <div className="doctor-card">
          <img src={doctorPhoto1} alt="Dr. Sachin" className="doctor-image" />
          <div className="doctor-info">
            <h3 className='d'>Dr. Sachin S. Ingle</h3>
            <a href='/aboutus' className="profile-button">View Profile</a>
            <div className="social-links">
              <a href="https://www.facebook.com/profile.php?id=100083347366159" target="_blank" rel="noopener noreferrer" className="facebook"><FaFacebookF /></a>
              <a href="https://youtube.com/@sachininglecancerlaparoscopy?si=Y4d2edXMTvNIhK9m" target="_blank" rel="noopener noreferrer" className="twitter"><FaYoutube /></a>
              <a href="https://www.instagram.com/khushihospital12?igsh=MWtlbjczem13MnZibA==" target="_blank" rel="noopener noreferrer" className="instagram"><FaInstagram /></a>
              <a href="https://wa.me/919225306774" target="_blank" rel="noopener noreferrer" className="whatsapp"><FaWhatsapp /></a>
            </div>
          </div>
        </div>
        <div className="doctor-card">
          <img src={doctorPhoto2} alt="Dr. Sonal" className="doctor-image" />
          <div className="doctor-info">
            <h3>Dr. Sonal S. Ingle</h3>
            <a href='/aboutus' className="profile-button">View Profile</a>
            <div className="social-links">
              <a href="https://www.facebook.com/profile.php?id=100083347366159" target="_blank" rel="noopener noreferrer" className="facebook"><FaFacebookF /></a>
              <a href="https://youtube.com/@sachininglecancerlaparoscopy?si=Y4d2edXMTvNIhK9m" target="_blank" rel="noopener noreferrer" className="twitter"><FaYoutube /></a>
              <a href="https://www.instagram.com/khushihospital12?igsh=MWtlbjczem13MnZibA==" target="_blank" rel="noopener noreferrer" className="instagram"><FaInstagram /></a>
              <a href="https://wa.me/919225306774" target="_blank" rel="noopener noreferrer" className="whatsapp"><FaWhatsapp /></a>
            </div>
          </div>
        </div>
      </div>
      <div className="scrolling-text-container">
        <div className="scrolling-text">Matrenity,Laparoscopic Surgery,Cancer Superspeciality And General Surgical Hospital. +91 92 25306774/+91 99 21395600</div>
      </div>
    </div>
  );
}

export default Profile;

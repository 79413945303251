import React from 'react';
import './Blog11.css'; // CSS फाइल को स्टाइलिंग के लिए इम्पोर्ट करें
import chemotherapyImage from './images/CANCER 4.avif'; // ब्लॉग 11 के लिए इमेज इम्पोर्ट करें

const Blog11 = () => {
  return (
    <div className="blog11-container">
      <header className="blog11-header">
        <h1>Chemotherapy</h1>
      </header>
      
      <div className="blog11-content">
        <img src={chemotherapyImage} alt="Chemotherapy" className="blog11-image" />
        <div className="blog11-text">
          <p>Chemotherapy is a type of cancer treatment that uses drugs to kill cancer cells. It can be used alone or in combination with other treatments such as surgery and radiation therapy.</p>
          <p>Key aspects of chemotherapy include:</p>
          <ul>
            <li>Targeting rapidly dividing cancer cells</li>
            <li>Administered through various methods including intravenous (IV), oral pills, and injections</li>
            <li>Possible side effects such as nausea, fatigue, and hair loss</li>
          </ul>
          <p>Our oncology team provides comprehensive chemotherapy services, including managing side effects and supporting patients throughout their treatment journey.</p>
          <p>For more information or to discuss treatment options, please contact us.</p>
        </div>
      </div>
    </div>
  );
}

export default Blog11;

import React from 'react';
import './Blog10.css'; // CSS फाइल को स्टाइलिंग के लिए इम्पोर्ट करें
import laparoscopicSurgeryImage from './images/CANCER 4.avif'; // ब्लॉग 10 के लिए इमेज इम्पोर्ट करें

const Blog10 = () => {
  return (
    <div className="blog10-container">
      <header className="blog10-header">
        <h1>Laparoscopic Surgery</h1>
      </header>
      
      <div className="blog10-content">
        <img src={laparoscopicSurgeryImage} alt="Laparoscopic Surgery" className="blog10-image" />
        <div className="blog10-text">
          <p>Laparoscopic surgery, also known as minimally invasive surgery, is a technique in which operations are performed through small incisions. It is beneficial for patients because it usually results in less pain, quicker recovery, and minimal scarring.</p>
          <p>Some key benefits of laparoscopic surgery include:</p>
          <ul>
            <li>Smaller incisions and less scarring</li>
            <li>Reduced pain and faster recovery time</li>
            <li>Shorter hospital stays</li>
            <li>Quicker return to normal activities</li>
          </ul>
          <p>Our hospital uses advanced laparoscopic techniques to treat various conditions, providing patients with the best possible outcomes.</p>
          <p>For more information or to schedule a consultation, please contact us.</p>
        </div>
      </div>
    </div>
  );
}

export default Blog10;

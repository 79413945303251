import React from 'react';
import img8 from './images/sachin sir.jpg';
import './Blog8.css';

const Blog8 = () => {
  return (
    <div className="blog-page">
      <h2 className='c'>Oncology Specialists</h2>
      <div className="content-wrapper">
        <img src={img8} alt="Cancer Specialist" className="blog-image" />
        <div className="additional-info">
          <h3>Dr. Sachin S. Ingle</h3>
          <p>M.S. General Surgery</p>
          <p>MCH Surgical Oncology Training For 3 Years at Tata Memorial Cancer Hospital Mumbai</p>
          <p>F.MAS, D.MAS: Fellowship and Diploma in minimal access surgery at SAGES (USA) approved center</p>
          <p>Memberships: IMA, ASI, IHPBA Indian Section, AMASI, Indian association of surgical oncology, I.M.A. Academy of Medical Specialties, Foundation of Head and neck oncology, IAGES, WALS</p>
          <p>Clinical Experience: Practicing cancer and laparoscopic surgeon. Vast Experience in treating various Diseases including Head and neck cancer, Skin cancers, Breast diseases, Gastrointestinal diseases, and Laparoscopic surgery</p>
          <p>Conferences: Attended many national and international conferences</p>
        </div>
      </div>
    </div>
  );
}

export default Blog8;

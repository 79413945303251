import React from 'react';
import './Blog12.css'; // CSS फाइल को स्टाइलिंग के लिए इम्पोर्ट करें
import gastrointestinalSurgeryImage from './images/gastro.jpg'; // ब्लॉग 12 के लिए इमेज इम्पोर्ट करें

const Blog12 = () => {
  return (
    <div className="blog12-container">
      <header className="blog12-header">
        <h1>Gastrointestinal Surgery</h1>
      </header>
      
      <div className="blog12-content">
        <img src={gastrointestinalSurgeryImage} alt="Gastrointestinal Surgery" className="blog12-image" />
        <div className="blog12-text">
          <p>Gastrointestinal (GI) surgery focuses on the treatment of conditions affecting the digestive tract, including the stomach, intestines, liver, and pancreas. This type of surgery may be required to treat various diseases and disorders.</p>
          <p>Our GI surgery services include:</p>
          <ul>
            <li>Surgical treatments for ulcers, tumors, and inflammatory bowel disease</li>
            <li>Advanced minimally invasive techniques</li>
            <li>Post-operative care and support</li>
          </ul>
          <p>Our skilled surgeons use the latest technology to ensure successful outcomes for our patients.</p>
          <p>For more details or to schedule a consultation, please reach out to us.</p>
        </div>
      </div>
    </div>
  );
}

export default Blog12;

import React from 'react';
import './Services.css';
import MultiCarousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import hospital from './images/cancer-care-center-in-jaipur.jpg';
import { Button, Card, Container } from 'react-bootstrap';
import lungcancer from './images/lung cancer.jpg';
import hematologicalcancer from './images/hemaonco.jpg';
import oncology from './images/oncology.webp';
import breastcancer from './images/breastcancer.jpg';
import { useNavigate } from 'react-router-dom';
import img1 from './images/lap1.jpeg';
import img2 from './images/lap2.jpeg';
import img3 from './images/lap3.jpeg';
import im1 from './images/s1.jpeg';
import im2 from './images/s2.webp';
import im3 from './images/s3.jpeg';


const Services = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div className='serviceshead'>METERNITY SERVICES</div>
      <Container>
        <MultiCarousel
          additionalTransfrom={0}
          arrows
          autoPlay
          autoPlaySpeed={2000}
          centerMode={false}
          containerClass="container-with-dots"
          draggable
          focusOnSelect={false}
          infinite
          keyBoardControl
          minimumTouchDrag={80}
          responsive={{
            desktop: {
              breakpoint: { max: 3000, min: 1024 },
              items: 3,
              partialVisibilityGutter: 40,
            },
            tablet: {
              breakpoint: { max: 1024, min: 464 },
              items: 2,
              partialVisibilityGutter: 30,
            },
            mobile: {
              breakpoint: { max: 464, min: 0 },
              items: 1,
              partialVisibilityGutter: 30,
            },
          }}
          showDots={false}
          sliderClass=""
          slidesToSlide={1}
          swipeable
        >
          <Card className='scard'>
            <Card.Img className='img' src={img1} />
            <Card.Body>
              <Card.Title><h4 className='service-title'>Gynecology Consultation</h4></Card.Title>
              <Card.Text>
                <h6 className='service-info'>Comprehensive consultation services for women's health including routine exams, reproductive health, and personalized care plans.</h6>
              </Card.Text>
              <Button className='sbutton' onClick={() => navigate('/eform')}>Contact Us</Button>
            </Card.Body>
          </Card>

          <Card className='scard'>
            <Card.Img className='img' src={img2} />
            <Card.Body>
              <Card.Title><h4 className='service-title'>Prenatal Care</h4></Card.Title>
              <Card.Text>
                <h6 className='service-info'>Dedicated prenatal care services for expecting mothers, ensuring a healthy pregnancy and safe delivery.</h6>               
              </Card.Text>
              <Button className='sbutton' onClick={() => navigate('/eform')}>Contact Us</Button>
            </Card.Body>
          </Card>

          <Card className='scard'>
            <Card.Img className='img' src={img3} />
            <Card.Body>
              <Card.Title><h4 className='service-title'>Postnatal Care</h4></Card.Title>
              <Card.Text>
                <h6 className='service-info'>Comprehensive postnatal care services for new mothers, focusing on recovery and health maintenance after childbirth.</h6>               
              </Card.Text>
              <Button className='sbutton' onClick={() => navigate('/eform')}>Contact Us</Button>
            </Card.Body>
          </Card>
        </MultiCarousel>
      </Container>
      
      <div className='serviceshead'>LAPAROSCOPIC SURGICAL SERVICES</div>
<Container>
  <MultiCarousel
    additionalTransfrom={0}
    arrows
    autoPlay
    autoPlaySpeed={2000}
    centerMode={false}
    containerClass="container-with-dots"
    draggable
    focusOnSelect={false}
    infinite
    keyBoardControl
    minimumTouchDrag={80}
    responsive={{
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        partialVisibilityGutter: 40,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        partialVisibilityGutter: 30,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        partialVisibilityGutter: 30,
      },
    }}
    showDots={false}
    sliderClass=""
    slidesToSlide={1}
    swipeable
  >
    <Card className='scard'>
      <Card.Img className='img' src={img1} />
      <Card.Body>
        <Card.Title><h4 className='service-title'>Laparoscopic Diagnostic Services</h4></Card.Title>
        <Card.Text>
          <h6 className='service-info'>Expert diagnostic laparoscopy services for investigating abdominal and pelvic conditions with minimal invasion.</h6>
        </Card.Text>
        <Button className='sbutton' onClick={() => navigate('/eform')}>Contact Us</Button>
      </Card.Body>
    </Card>

    <Card className='scard'>
      <Card.Img className='img' src={img2} />
      <Card.Body>
        <Card.Title><h4 className='service-title'>Laparoscopic Surgery</h4></Card.Title>
        <Card.Text>
          <h6 className='service-info'>Advanced laparoscopic surgeries including gallbladder removal, appendectomies, and hernia repairs with minimal recovery time.</h6>               
        </Card.Text>
        <Button className='sbutton' onClick={() => navigate('/eform')}>Contact Us</Button>
      </Card.Body>
    </Card>

    <Card className='scard'>
      <Card.Img className='img' src={img3} />
      <Card.Body>
        <Card.Title><h4 className='service-title'>Gynecological Laparoscopy</h4></Card.Title>
        <Card.Text>
          <h6 className='service-info'>Specialized laparoscopic procedures for gynecological conditions, including endometriosis treatment, fibroid removal, and more.</h6>               
        </Card.Text>
        <Button className='sbutton' onClick={() => navigate('/eform')}>Contact Us</Button>
      </Card.Body>
    </Card>
  </MultiCarousel>
</Container>

      <div className='serviceshead'> CANCER SERVICES</div>
      <Container>
        <MultiCarousel
          additionalTransfrom={0}
          arrows
          autoPlay
          autoPlaySpeed={2000}
          centerMode={false}
          containerClass="container-with-dots"
          draggable
          focusOnSelect={false}
          infinite
          keyBoardControl
          minimumTouchDrag={80}
          responsive={{
            desktop: {
              breakpoint: { max: 3000, min: 1024 },
              items: 3,
              partialVisibilityGutter: 40,
            },
            tablet: {
              breakpoint: { max: 1024, min: 464 },
              items: 2,
              partialVisibilityGutter: 30,
            },
            mobile: {
              breakpoint: { max: 464, min: 0 },
              items: 1,
              partialVisibilityGutter: 30,
            },
          }}
          showDots={false}
          sliderClass=""
          slidesToSlide={1}
          swipeable
        >
          <Card className='scard'>
            <Card.Img className='img' src={oncology} />
            <Card.Body>
              <Card.Title><h4 className='service-title'>Oncology</h4></Card.Title>
              <Card.Text>
                <h6 className='service-info'>Comprehensive cancer care services including
                   diagnosis, treatment, and follow-up care for various types of 
                   cancers such as leukemia, lymphoma, and solid tumors.</h6>
              </Card.Text>
              <Button className='sbutton' onClick={() => navigate('/eform')}>Contact Us</Button>
            </Card.Body>
          </Card>

          <Card className='scard'>
            <Card.Img className='img' src={hospital} />
            <Card.Body>
              <Card.Title><h4 className='service-title'>Consultation</h4></Card.Title>
              <Card.Text>
                <h6 className='service-info'> Initial consultation with oncologist for 
                  evaluation, discussion of treatment options, and personalized care plan development.</h6>               
              </Card.Text>
              <Button className='sbutton' onClick={() => navigate('/eform')}>Contact Us</Button>
            </Card.Body>
          </Card>

          <Card className='scard'>
            <Card.Img className='img' src={lungcancer} />
            <Card.Body>
              <Card.Title><h4 className='service-title'>Lung Cancer</h4></Card.Title>
              <Card.Text>
                <h6 className='service-info'>Specialized treatment modalities including surgery, chemotherapy, radiation therapy,
                   and targeted therapy for lung cancer patients, along with supportive care services.</h6>               
              </Card.Text>
              <Button className='sbutton' onClick={() => navigate('/eform')}>Contact Us</Button>
            </Card.Body>
          </Card>

          <Card className='scard'>
            <Card.Img className='img' src={breastcancer} />
            <Card.Body>
              <Card.Title><h4 className='service-title'>Breast cancer</h4></Card.Title>
              <Card.Text>
                <h6 className='service-info'>Advanced therapies such as hormone therapy, immunotherapy, and breast-conserving surgeries for breast
                   cancer patients, alongside genetic testing and counseling services.</h6>           
              </Card.Text>
              <Button className='sbutton' onClick={() => navigate('/eform')}>Contact Us</Button>
            </Card.Body>
          </Card>

          <Card className='scard'>
            <Card.Img className='img' src={hematologicalcancer} />
            <Card.Body>
              <Card.Title><h4 className='service-title'>Hematological Oncology</h4></Card.Title>
              <Card.Text>
                <h6 className='service-info'>Specialized consultation with hematologist-oncologist for patients with blood cancers. Includes 
                   targeted therapies, as well as management of associated symptoms and supportive care.</h6>               
              </Card.Text>
              <Button className='sbutton' onClick={() => navigate('/eform')}>Contact Us</Button>
            </Card.Body>
          </Card>
        </MultiCarousel>
      </Container>

      <div className='serviceshead'> GENERAL SURGICAL SERVICES</div>
<Container>
  <MultiCarousel
    additionalTransfrom={0}
    arrows
    autoPlay
    autoPlaySpeed={2000}
    centerMode={false}
    containerClass="container-with-dots"
    draggable
    focusOnSelect={false}
    infinite
    keyBoardControl
    minimumTouchDrag={80}
    responsive={{
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        partialVisibilityGutter: 40,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        partialVisibilityGutter: 30,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        partialVisibilityGutter: 30,
      },
    }}
    showDots={false}
    sliderClass=""
    slidesToSlide={1}
    swipeable
  >
    <Card className='scard'>
      <Card.Img className='img' src={im1} />
      <Card.Body>
        <Card.Title><h4 className='service-title'>General Surgery</h4></Card.Title>
        <Card.Text>
          <h6 className='service-info'>Our expert general surgeons provide a wide range of surgical procedures, including appendectomies, hernia repairs, and gallbladder surgeries, focusing on safety and recovery.</h6>
        </Card.Text>
        <Button className='sbutton' onClick={() => navigate('/eform')}>Contact Us</Button>
      </Card.Body>
    </Card>

    <Card className='scard'>
      <Card.Img className='img' src={im2} />
      <Card.Body>
        <Card.Title><h4 className='service-title'>Cancer Surgery</h4></Card.Title>
        <Card.Text>
          <h6 className='service-info'>Specialized cancer surgeries are provided by our team to remove tumors and cancerous tissues, tailored to each patient's specific needs for optimal outcomes.</h6>               
        </Card.Text>
        <Button className='sbutton' onClick={() => navigate('/eform')}>Contact Us</Button>
      </Card.Body>
    </Card>

    <Card className='scard'>
      <Card.Img className='img' src={im3} />
      <Card.Body>
        <Card.Title><h4 className='service-title'>Minimally Invasive Surgery</h4></Card.Title>
        <Card.Text>
          <h6 className='service-info'>Offering minimally invasive surgical options, including laparoscopic and robotic-assisted surgeries, for quicker recovery and less postoperative pain.</h6>               
        </Card.Text>
        <Button className='sbutton' onClick={() => navigate('/eform')}>Contact Us</Button>
      </Card.Body>
    </Card>
  </MultiCarousel>
</Container>  
    </div>
  );
}

export default Services;

import React from 'react';
import img1 from '../src/images/Pregnancy-Care.png';
import img2 from '../src/images/pregancy-care-1.jpg';
import './Blog1.css'; // Ensure you create this CSS file for styling

const Blog1 = () => {
  return (
    <div className="blog-page">
      <h2 className='blog-sub-image'>Comprehensive Pregnancy Care</h2>
      <img src={img1} alt="Gynecologist" className="blog-sub-image" />
      <p>
        Pregnancy is a remarkable journey filled with unique experiences, joys, and challenges. At our hospital, we are dedicated to providing expectant mothers with comprehensive care to ensure a healthy pregnancy, safe delivery, and smooth postpartum recovery.
      </p>
      
      <section className="blog-section">
        <h3>Prenatal Care</h3>
        <img src={img2} alt="Prenatal Care" className="blog-sub-image" />
        <p>
          Prenatal care is crucial for monitoring the health of both the mother and the developing baby. Our services include regular check-ups, ultrasounds, and screenings to track the baby's growth and detect any potential issues early on. We provide personalized care plans tailored to each mother’s needs, ensuring a healthy pregnancy journey.
        </p>
      </section>
      
      <section className="blog-section">
        <h3>Nutrition and Lifestyle Guidance</h3>
        <p>
          Proper nutrition and a healthy lifestyle are vital during pregnancy. Our team of dietitians and health professionals offers comprehensive advice on balanced diets, necessary supplements, and healthy habits to support the well-being of both mother and baby.
        </p>
      </section>
      
      <section className="blog-section">
        <h3>Labor and Delivery</h3>
        <p>
          We provide a supportive and safe environment for labor and delivery. Our state-of-the-art facilities are equipped to handle all types of deliveries, and our experienced staff is prepared to manage any complications. We prioritize the mother’s comfort and preferences, including options for natural birth, pain management, and cesarean sections.
        </p>
      </section>
      
      <section className="blog-section">
        <h3>Postpartum Care</h3>
        <p>
          After childbirth, our care continues with postpartum support. We offer assistance with breastfeeding, recovery monitoring, and emotional support to help new mothers adjust to life with their newborns. Our goal is to ensure both the mother and baby are healthy and thriving during the postpartum period.
        </p>
      </section>
      
      <section className="blog-section">
        <h3>Support Services</h3>
        <p>
          We understand that pregnancy can be overwhelming, and we provide various support services to help. These include childbirth education classes, support groups, and counseling services. Our team is here to offer guidance, answer questions, and provide the support you need throughout your pregnancy journey.
        </p>
      </section>
      
      <section className="blog-section">
        <h3>Why Choose Us?</h3>
        <p>
          Our hospital is committed to providing the highest standard of care for expectant mothers. With a team of experienced obstetricians, gynecologists, and healthcare professionals, we ensure a safe and comfortable experience for both mother and baby. Our patient-centered approach means we listen to your concerns, respect your choices, and provide personalized care every step of the way.
        </p>
      </section>
    </div>
  );
}

export default Blog1;

import React from 'react';
import './Blog17.css'; // CSS फाइल को स्टाइलिंग के लिए इम्पोर्ट करें
import diabeticCareImage from './images/diabetic.jpg'; // ब्लॉग 17 के लिए इमेज इम्पोर्ट करें

const Blog17 = () => {
  return (
    <div className="blog17-container">
      <header className="blog17-header">
        <h1>Diabetic Care</h1>
      </header>
      
      <div className="blog17-content">
        <img src={diabeticCareImage} alt="Diabetic Care" className="blog17-image" />
        <div className="blog17-text">
          <p>Diabetic care involves managing and treating diabetes to help patients live a healthy and balanced life. Our comprehensive approach includes medical treatment, lifestyle changes, and ongoing support.</p>
          <p>Our diabetic care services include:</p>
          <ul>
            <li>Individualized treatment plans for diabetes management</li>
            <li>Nutritional counseling and education</li>
            <li>Monitoring and managing blood sugar levels</li>
            <li>Support for complications related to diabetes</li>
          </ul>
          <p>Our dedicated team works closely with patients to provide the best possible care and improve quality of life.</p>
          <p>For more information or to schedule an appointment, please contact us.</p>
        </div>
      </div>
    </div>
  );
}

export default Blog17;

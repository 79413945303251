import React from 'react';
import './Blog9.css'; // CSS फाइल को स्टाइलिंग के लिए इम्पोर्ट करें
import cancerSurgeryImage from './images/cancer surgery.jpeg'; // ब्लॉग 9 के लिए इमेज इम्पोर्ट करें

const Blog9 = () => {
  return (
    <div className="blog9-container">
      <header className="blog9-header">
        <h1>Cancer Surgery (Oncosurgery)</h1>
      </header>
      
      <div className="blog9-content">
        <img src={cancerSurgeryImage} alt="Cancer Surgery" className="blog9-image" />
        <div className="blog9-text">
          <p>Cancer surgery, or oncosurgery, is a critical component in the treatment of cancer. It involves the removal of cancerous tissues or tumors from the body. Depending on the type and stage of cancer, surgery can be used to:
          </p>
          <ul>
            <li>Remove the tumor and surrounding tissue</li>
            <li>Reduce the size of the tumor before other treatments (like chemotherapy or radiation)</li>
            <li>Relieve symptoms caused by the tumor</li>
          </ul>
          <p>Our hospital is equipped with state-of-the-art technology to ensure precise and effective cancer surgeries. Our team of experienced surgeons works closely with patients to develop individualized treatment plans aimed at the best possible outcomes.</p>
          <p>For more information on cancer surgery or to schedule a consultation, please contact us.</p>
        </div>
      </div>
    </div>
  );
}

export default Blog9;

import React from 'react';
import './Blog18.css'; // CSS फाइल को स्टाइलिंग के लिए इम्पोर्ट करें
import obstetricsGynecologyImage from './images/gny.jpeg'; // ब्लॉग 18 के लिए इमेज इम्पोर्ट करें

const Blog18 = () => {
  return (
    <div className="blog18-container">
      <header className="blog18-header">
        <h1>Obstetrics & Gynecology</h1>
      </header>
      
      <div className="blog18-content">
        <img src={obstetricsGynecologyImage} alt="Obstetrics & Gynecology" className="blog18-image" />
        <div className="blog18-text">
          <p>Obstetrics and gynecology (OB/GYN) is a medical specialty that focuses on the female reproductive system and childbirth. It encompasses a wide range of services aimed at maintaining women's health and managing pregnancy.</p>
          <p>Our OB/GYN services include:</p>
          <ul>
            <li>Routine gynecological exams and screenings</li>
            <li>Pre-natal care and management of high-risk pregnancies</li>
            <li>Labor and delivery support</li>
            <li>Postpartum care and support</li>
            <li>Menopause management and hormonal therapy</li>
          </ul>
          <p>Our team of experienced obstetricians and gynecologists is dedicated to providing comprehensive care for women at all stages of life, ensuring both maternal and fetal health.</p>
          <p>For more information or to schedule a consultation, please contact us.</p>
        </div>
      </div>
    </div>
  );
}

export default Blog18;

import React from 'react';
import './Blog13.css'; // CSS फाइल को स्टाइलिंग के लिए इम्पोर्ट करें
import herniaSurgeryImage from './images/hernia.jpg'; // ब्लॉग 13 के लिए इमेज इम्पोर्ट करें

const Blog13 = () => {
  return (
    <div className="blog13-container">
      <header className="blog13-header">
        <h1>Hernia Surgery</h1>
      </header>
      
      <div className="blog13-content">
        <img src={herniaSurgeryImage} alt="Hernia Surgery" className="blog13-image" />
        <div className="blog13-text">
          <p>Hernia surgery is performed to repair a hernia, which occurs when an organ or tissue protrudes through a weak spot in the abdominal wall. This surgery aims to alleviate pain and prevent complications.</p>
          <p>Key aspects of hernia surgery include:</p>
          <ul>
            <li>Repairing the hernia with mesh or sutures</li>
            <li>Minimally invasive options for quicker recovery</li>
            <li>Pre- and post-operative care to ensure a smooth recovery</li>
          </ul>
          <p>Our experienced surgeons provide effective hernia repair with a focus on patient comfort and recovery.</p>
          <p>For more information or to schedule a consultation, please contact us.</p>
        </div>
      </div>
    </div>
  );
}

export default Blog13;

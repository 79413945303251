import React from 'react';
import './Blog15.css'; // CSS फाइल को स्टाइलिंग के लिए इम्पोर्ट करें
import hepatobiliarySurgeryImage from './images/hepa.jpg'; // ब्लॉग 15 के लिए इमेज इम्पोर्ट करें

const Blog15 = () => {
  return (
    <div className="blog15-container">
      <header className="blog15-header">
        <h1>Hepatobiliary & Pancreatic Surgery</h1>
      </header>
      
      <div className="blog15-content">
        <img src={hepatobiliarySurgeryImage} alt="Hepatobiliary & Pancreatic Surgery" className="blog15-image" />
        <div className="blog15-text">
          <p>Hepatobiliary and pancreatic surgery involves the treatment of conditions affecting the liver, bile ducts, and pancreas. These complex surgeries require specialized expertise and advanced techniques.</p>
          <p>Our services include:</p>
          <ul>
            <li>Surgery for liver cancer, gallbladder disease, and pancreatic disorders</li>
            <li>Advanced minimally invasive techniques</li>
            <li>Comprehensive pre- and post-operative care</li>
          </ul>
          <p>We are dedicated to providing the highest level of care for patients undergoing hepatobiliary and pancreatic surgery.</p>
          <p>For more details or to schedule a consultation, please contact us.</p>
        </div>
      </div>
    </div>
  );
}

export default Blog15;

import React, { useRef } from 'react';
import './Enquiryform.css';
import { FaUser, FaEnvelope, FaPhoneAlt, FaTag, FaComment } from 'react-icons/fa';
import emailjs from '@emailjs/browser';

const EnquiryForm = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_jnlv4ii', 'template_3tagnok', form.current, '43a27iB13jrD-uugv')
      .then((result) => {
        console.log(result.text);
        alert("Mail sent successfully!");
      }, (error) => {
        console.log(error.text);
      });
    e.target.reset();
  };

  return (
    <div className="econtainer">
      <div className="content-wrapper">
        <div className="form-container">
          <h2 className="fhead">Contact Us</h2>
          <form ref={form} onSubmit={sendEmail}>
            <div className="form-group">
              <i><FaUser /></i>
              <label htmlFor="name">Name:</label>
              <input type="text" id="name" name="user_name" placeholder="Your name.." required />
            </div>
            <div className="form-group">
              <i><FaEnvelope /></i>
              <label htmlFor="email">Email:</label>
              <input type="email" id="email" name="user_email" placeholder="Your email.." required />
            </div>
            <div className="form-group">
              <i><FaPhoneAlt /></i>
              <label htmlFor="phone">WhatsApp Number:</label>
              <input type="tel" id="phone" name="user_number" placeholder="Your phone.." />
            </div>
            <div className="form-group">
              <i><FaTag /></i>
              <label htmlFor="subject">Subject:</label>
              <input type="text" id="subject" name="subject" placeholder="Your subject.." />
            </div>
            <div className="form-group">
              <i><FaComment /></i>
              <label htmlFor="message">Message:</label>
              <textarea name="message" rows={3} placeholder="Your message..."></textarea>
            </div>
            <div>
              <button className="ebt-container" type="submit">Submit</button>
            </div>
          </form>
        </div>
        <div className="map-container">
          <iframe 
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3724.5752969780624!2d75.55126659572124!3d21.00965490442162!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd90fa0345939e3%3A0xb20fef96211b6da!2sKHUSHI%20HOSPITAL%20(Maternity%2Claparoscopy%2Ccancer%20superspeciality%20and%20general%20surgical%20hospital)!5e0!3m2!1sen!2sin!4v1718935149196!5m2!1sen!2sin"
            width="600" 
            height="450" 
            allowFullScreen="" 
            loading="lazy" 
            referrerPolicy="no-referrer-when-downgrade"
            className='map'>
          </iframe>
          <div className="map-overlay" onClick={() => window.open("https://www.google.com/maps/place/KHUSHI+HOSPITAL/@21.009655,75.551267,17z/data=!3m1!4b1!4m5!3m4!1s0x3bd90fa0345939e3:0xb20fef96211b6da!8m2!3d21.009655!4d75.5534553", "_blank")}>
            View on Google Maps
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnquiryForm;

import React from 'react';
import img3 from './images/sur.jpg';


const BlogGeneralSurgery = () => {
  return (
    <div className="blog-page">
      <h2 className='blog-sub-image'>General Surgery</h2>
      <img src={img3} alt="General Surgery" className="blog-sub-image" />
      
      <p>
        Our General Surgery department provides a comprehensive range of surgical procedures to treat a wide variety of conditions. Our experienced surgeons are skilled in both traditional and minimally invasive techniques, ensuring the best possible outcomes for our patients.
      </p>

      <section className="blog-section">
        <h3>Diagnostic and Preoperative Services</h3>
        <p>
          We offer detailed diagnostic services and preoperative consultations to ensure that each patient receives the most appropriate surgical care. This includes imaging, lab tests, and thorough evaluations to plan the best surgical approach.
        </p>
      </section>

      <section className="blog-section">
        <h3>Common Procedures</h3>
        <p>
          Our general surgery team performs a wide range of common procedures, including appendectomies, hernia repairs, gallbladder surgeries, and more. We utilize the latest techniques to minimize recovery time and enhance patient safety.
        </p>
      </section>

      <section className="blog-section">
        <h3>Minimally Invasive Surgery</h3>
        <p>
          We specialize in minimally invasive surgeries, which use smaller incisions compared to traditional surgery. These techniques often result in less pain, shorter hospital stays, and faster recovery times. Laparoscopic and robotic-assisted surgeries are some of the advanced options we provide.
        </p>
      </section>

      <section className="blog-section">
        <h3>Postoperative Care and Recovery</h3>
        <p>
          Postoperative care is crucial for a smooth recovery. Our team provides comprehensive postoperative care, including pain management, physical therapy, and follow-up visits to monitor recovery and prevent complications.
        </p>
      </section>

      <section className="blog-section">
        <h3>Why Choose Our General Surgery Department?</h3>
        <p>
          Our general surgery team is committed to providing high-quality, compassionate care. We offer a patient-centered approach, combining advanced surgical techniques with personalized care plans. Our goal is to ensure each patient achieves the best possible outcome.
        </p>
      </section>
    </div>
  );
}

export default BlogGeneralSurgery;

import React from 'react';
import img2 from './images/gy2.jpg';
import './Blog2.css'; // Ensure you create this CSS file for styling

const Blog2 = () => {
  return (
    <div className="blog-page">
      <h2 className='blog-sub-image'>Gynecological Services</h2>
      <img src={img2} alt="Gynecologist" className="blog-sub-image" />
      
      <p>
        At our hospital, we offer a comprehensive range of gynecological services aimed at addressing women's health needs throughout their lives. Our dedicated team of gynecologists and healthcare professionals provides expert care and personalized treatment plans tailored to each patient.
      </p>

      <section className="blog-section">
        <h3>Routine Exams and Screenings</h3>
        <p>
          Regular gynecological exams are essential for maintaining women's health. We offer routine screenings, such as Pap smears and mammograms, to detect any abnormalities early and provide timely interventions.
        </p>
      </section>

      <section className="blog-section">
        <h3>Reproductive Health</h3>
        <p>
          Our services include contraceptive counseling, family planning, and management of reproductive health issues. We empower women to make informed decisions about their reproductive health and provide support throughout their reproductive journey.
        </p>
      </section>

      <section className="blog-section">
        <h3>Minimally Invasive Surgeries</h3>
        <p>
          When surgery is necessary, we specialize in minimally invasive procedures. These techniques reduce recovery time and minimize discomfort, ensuring our patients can return to their daily activities sooner with less postoperative pain.
        </p>
      </section>

      <section className="blog-section">
        <h3>Gynecological Conditions</h3>
        <p>
          We diagnose and treat a wide range of gynecological conditions, including menstrual disorders, pelvic pain, ovarian cysts, and menopausal symptoms. Our goal is to improve our patients' quality of life by addressing these conditions effectively and compassionately.
        </p>
      </section>

      <section className="blog-section">
        <h3>Why Choose Us?</h3>
        <p>
          Choosing our hospital means choosing expert care and a commitment to women's health. We prioritize patient comfort, confidentiality, and personalized attention, ensuring each woman receives the highest standard of care in a supportive environment.
        </p>
      </section>
    </div>
  );
}

export default Blog2;

// AboutUs.js

import React from 'react';
import { FaHospital, FaUser } from 'react-icons/fa';
import './AboutUs.css'; 
import doctorPhoto1 from './images/sachin sir.jpg';
import doctorPhoto2 from './images/sonal.jpg';

const AboutUs = () => {
  const imageStyle = {
    width: '100%',
    height: 'auto',
    objectFit: 'cover',
    borderRadius: '40px',
    boxShadow: '0 5px 10px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s ease-in-out',
  };

  const doctorNameStyle = {
    fontSize: '1.5rem',
    textAlign: 'center',
    margin: '1vw 0',
  };

  return (
    <div className="sectionStyle">
      <h2 className="headingStyle">
        About Us
        <span className="underlineStyle"></span>
      </h2>
      <div className="profileContainerStyle">
        <div className="profileStyle">
          <div className="doctor-photo-wrapper">
            <img src={doctorPhoto1} alt="Dr. Sachin S. Ingle" style={imageStyle} />
          </div>
          <div className="doctor-info">
            <h3 className="doctor-name" style={doctorNameStyle}>Dr. Sachin S. Ingle</h3>
            <p className="about-containerpara">
              M.S. General Surgery<br />
              MCH Surgical Oncology Training For 3 Years at Tata Memorial Cancer Hospital Mumbai<br />
              F.MAS, D.MAS: Fellowship and Diploma in minimal access surgery at SAGES (USA) approved center<br />
              Memberships: IMA, ASI, IHPBA Indian Section, AMASI, Indian association of surgical oncology, I.M.A. Academy of Medical Specialties, Foundation of Head and neck oncology, IAGES, WALS<br />
              Clinical Experience: Practicing cancer and laparoscopic surgeon. Vast Experience in treating various Diseases including Head and neck cancer, Skin cancers, Breast diseases, Gastrointestinal diseases, and Laparoscopic surgery<br />
              Conferences: Attended many national and international conferences
            </p>
          </div>
        </div>
        <div className="profileStyle">
          <div className="doctor-photo-wrapper">
            <img src={doctorPhoto2} alt="Dr. Sonal S. Ingle" style={imageStyle} />
          </div>
          <div className="doctor-info">
            <h3 className="doctor-name" style={doctorNameStyle}>Dr. Sonal S. Ingle</h3>
            <p className="about-containerpara">
              MS, FMAS, DMAS<br />
              Consultant Obstetrician, Gynecologist, Infertility Consultant<br />
              M.B.B.S. from SETH GS Medical College, Mumbai<br />
              MS In OBGY Gynecology From MGM medical college Aurangabad, Maharashtra<br />
              F.MAS, D.MAS: Fellowship and Diploma in minimal access surgery at SAGES (USA) approved center<br />
              USG Training: FOGSI recognized centre<br />
              Clinical Experience: Practicing gynaecologist, infertility specialist and gynaec laparoscopic surgeon. Vast Experience in treating various gynaecological Diseases laparoscopically and by open method. Routinely performing total laparoscopic hysterectomy, laparoscopic tubal ligation, laparoscopic ectopic pregnancy operation. Routinely performing almost all hysteroscopic operations
            </p>
          </div>
        </div>
      </div>
      <div className="serviceContainerStyle">
        <h1 className="mainHeadingStyle">Why Choose Khushi Hospital?</h1>
        <div className="rowStyle">
          <div className="serviceCardStyle">
            <div className="Aicon"><FaHospital /></div>
            <div className="service-cardhead">Comprehensive Care</div>
            <div className="service-cardpara">Complete diagnostic and treatment facilities available.</div>
          </div>
          <div className="serviceCardStyle">
            <div className="Aicon"><FaUser /></div>
            <div className="service-cardhead">Personalized Treatment</div>
            <div className="service-cardpara">Tailored treatment plans for different conditions.</div>
          </div>
          <div className="serviceCardStyle">
            <div className="Aicon"><FaUser /></div>
            <div className="service-cardhead">Gynecology Services</div>
            <div className="service-cardpara">Specialized care for women's health issues.</div>
          </div>
          <div className="serviceCardStyle">
            <div className="Aicon"><FaHospital /></div>
            <div className="service-cardhead">Cancer Services</div>
            <div className="service-cardpara">Advanced care and treatment for cancer patients.</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;

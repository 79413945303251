import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Clienttestimonial.css';
import './Home.css';
import profile from './images/sonal.jpg';
import profile1 from './images/sachin sir.jpg';
import hospitalBackground1 from './images/sachin.jpeg';
import img1 from './images/preg.jpg';
import img2 from './images/gy2.jpg';
import img3 from './images/sur.jpg';
import img4 from './images/CANCER 4.avif';
import img5 from './images/cancer 3.webp';
import img6 from './images/cnacer5.jpeg';
import img7 from './images/can7.webp';
import img8 from './images/can10.jpeg';
import img9 from './images/cancer surgery.jpeg';
import img10 from './images/gastro.jpg';
import img11 from './images/hernia.jpg';
import img12 from './images/breast.jpg';
import img13 from './images/hepa.jpg';
import img14 from './images/genaral.jpeg';
import img15 from './images/diabetic.jpg';
import img21 from './images/baby.jpeg';
import logo from './images/khushilogo.jpeg';

const Home = () => {
  const sentences = [
    "Providing comprehensive healthcare services with compassion and care.",
    "Expert care from experienced medical professionals.",
    "State-of-the-art facilities for your health needs.",
    "Your health, our priority.",
  ];

  const videoUrls = [
    "https://www.youtube.com/watch?v=cp80ZQMsMk0",
    "https://youtu.be/U73r6Uhtag4?si=eD9F3JWZOKCu2jdH",
    "https://youtu.be/DT__8SFVvnM?si=SujwHjXSSgE7VZ4S",
    "https://www.youtube.com/watch?v=MQcXAqCXAEo",
    "https://www.youtube.com/watch?v=-fxRAtLanWU",
    "https://www.youtube.com/watch?v=RnNzCInHHOE"
  ];

  const [currentSentenceIndex, setCurrentSentenceIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentSentenceIndex((prevIndex) => (prevIndex + 1) % sentences.length);
    }, 3000); // Change sentence every 3 seconds

    return () => clearInterval(intervalId); // Clean up the interval on component unmount
  }, [sentences.length]);

  const getVideoId = (url) => {
    const urlParts = url.split('?');
    if (urlParts.length < 2) return null;
    const queryParams = new URLSearchParams(urlParts[1]);
    return queryParams.get('v');
  };

  return (
    <div className="home">
      <div className="hero-section">
        <div className="hero-content1">
          <img src={hospitalBackground1} alt="Dr. Sachin S. Ingle" />
        </div>
        <div className="hero-content">
          <div className="text-overlay">
           
          <img src={logo} alt="Logo" className="logo" />
          </div>
  </div>
</div>

      <div className="doctors-section">
        <h2 className="meet-doctors">Meet Our Doctors</h2>
        <div className="doctor-profile-container">
          <div className="doctor-profile">
            <img src={profile1} alt="Dr. Sachin S. Ingle" className="doctor-image" />
            <div className="doctor-info">
             
              <p className='M'>M.S. (GENERAL SURGEON), CANCER SURGERY (TATA CANCER HOSPITAL, MUMBAI.)</p>
              <p>F.MAS, D.MAS. FIAGES (LAPAROSCOPIC SURGERY)</p>
              <p>CONSULTANT CANCER & LAPAROSCOPIC SURGEON</p>
            </div>
          </div>
          
          <div className="doctor-profile">
            <img src={profile} alt="Dr. Sonal S. Ingle" className="doctor-image" />
            <div className="doctor-info">
             
              <p>M.S. (OB/GYN) (GYNAECOLOGY), F.MAS, D.MAS. (LAPAROSCOPIC SURGERY)</p>
              <p>CONSULTANT OBSTETRICIAN, GYNAECOLOGY, LAPAROSCOPIC SURGEON & INFERTILITY CONSULTANT</p>
            </div>
          </div>
        </div>
      </div>

      <div className="testimonial-container">
        <h2 className='testimonialh2'>Patients Speak</h2>
        <p className='testimonialp'>Hear the heartwarming stories of patients overcoming difficult afflictions</p>
        <div className="testimonial-videos">
          {videoUrls.map((videoUrl, index) => (
            <div key={index} className="testimonial-video">
              <iframe
                src={`https://www.youtube.com/embed/${getVideoId(videoUrl)}`}
                title={`Client Testimonial ${index + 1}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          ))}
        </div>
      </div>
      <div className="our-services-section">
        <h2 className="our-services">Our Services</h2>
      </div>
      <div className="image-blog-section" >
        {/* Existing Blog Sections */}
        <div className="image-blog">
          <Link to="/blog1">
            <img src={img1} alt="Gynecologist" className="blog-image" />
            <div className="blog-info">
              <h3>Comprehensive Pregnancy Care</h3>
              <p>We offer comprehensive care for expectant mothers, including prenatal check-ups, nutrition advice, and postpartum support.</p>
            </div>
          </Link>
        </div>
        
        <div className="image-blog">
          <Link to="/blog18">
            <img src={img21} alt="Obstetrics & Gynecology" className="blog-image" />
            <div className="blog-info">
              <h3>Obstetrics & Gynecology</h3>
              <p>Expert care in obstetrics and gynecology, offering services from prenatal care to advanced gynecological treatments.</p>
            </div>
          </Link>
        </div>
        <div className="image-blog">
          <Link to="/blog2">
            <img src={img2} alt="Gynecologist" className="blog-image" />
            <div className="blog-info">
              <h3>Gynecological Services</h3>
              <p>Our gynecological services cover routine exams, reproductive health, and minimally invasive surgeries, including laparoscopy.</p>
            </div>
          </Link>
        </div>

        <div className="image-blog">
          <Link to="/blog5">
            <img src={img5} alt="Cancer Specialist" className="blog-image" />
            <div className="blog-info">
              <h3>Advanced Cancer Treatments</h3>
              <p>We offer cutting-edge treatments for various types of cancer, including chemotherapy, radiation, and surgical options.</p>
            </div>
          </Link>
        </div>

        <div className="image-blog">
          <Link to="/blog6">
            <img src={img6} alt="Cancer Specialist" className="blog-image" />
            <div className="blog-info">
              <h3>Cancer Research</h3>
              <p>Our research team is dedicated to finding new and effective treatments for cancer patients.</p>
            </div>
          </Link>
        </div>

        <div className="image-blog">
          <Link to="/blog7">
            <img src={img7} alt="Cancer Specialist" className="blog-image" />
            <div className="blog-info">
              <h3>Patient Support</h3>
              <p>We provide comprehensive support services for cancer patients, including counseling and palliative care.</p>
            </div>
          </Link>
        </div>

        <div className="image-blog">
          <Link to="/blog8">
            <img src={img8} alt="Cancer Specialist" className="blog-image" />
            <div className="blog-info">
              <h3>Oncology Specialists</h3>
              <p>Our team of oncology specialists is here to provide personalized care for each cancer patient.</p>
            </div>
          </Link>
        </div>

        {/* New Blog Sections */}
        <div className="image-blog">
          <Link to="/blog9">
            <img src={img9} alt="Cancer Surgery" className="blog-image" />
            <div className="blog-info">
              <h3>Cancer Surgery (Oncosurgery)</h3>
              <p>Expert care for cancer surgeries, including oncosurgery, tailored to individual patient needs.</p>
            </div>
          </Link>
        </div>

        <div className="image-blog">
          <Link to="/blog10">
            <img src={img3} alt="Laparoscopic Surgery" className="blog-image" />
            <div className="blog-info">
              <h3>Laparoscopic Surgery</h3>
              <p>Minimally invasive laparoscopic surgery with advanced techniques and technology.</p>
            </div>
          </Link>
        </div>

        <div className="image-blog">
          <Link to="/blog11">
            <img src={img4} alt="Chemotherapy" className="blog-image" />
            <div className="blog-info">
              <h3>Chemotherapy</h3>
              <p>Comprehensive chemotherapy services to treat various types of cancer with precision and care.</p>
            </div>
          </Link>
        </div>

        <div className="image-blog">
          <Link to="/blog12">
            <img src={img10} alt="Gastrointestinal Surgery" className="blog-image" />
            <div className="blog-info">
              <h3>Gastrointestinal Surgery</h3>
              <p>Advanced surgical treatments for gastrointestinal issues, ensuring optimal patient outcomes.</p>
            </div>
          </Link>
        </div>

        <div className="image-blog">
          <Link to="/blog13">
            <img src={img11} alt="Hernia Surgery" className="blog-image" />
            <div className="blog-info">
              <h3>Hernia Surgery</h3>
              <p>Effective and minimally invasive hernia repair surgeries performed by experienced specialists.</p>
            </div>
          </Link>
        </div>

        <div className="image-blog">
          <Link to="/blog14">
            <img src={img12} alt="Breast Surgery" className="blog-image" />
            <div className="blog-info">
              <h3>Breast Surgery</h3>
              <p>Expert care for breast surgeries, including cancer treatment and cosmetic procedures.</p>
            </div>
          </Link>
        </div>

        <div className="image-blog">
          <Link to="/blog15">
            <img src={img13} alt="Hepatobiliary & Pancreatic Surgery" className="blog-image" />
            <div className="blog-info">
              <h3>Hepatobiliary & Pancreatic Surgery</h3>
              <p>Specialized surgeries for hepatobiliary and pancreatic conditions, with a focus on patient care.</p>
            </div>
          </Link>
        </div>

        <div className="image-blog">
          <Link to="/blog16">
            <img src={img14} alt="General Surgery" className="blog-image" />
            <div className="blog-info">
              <h3>General Surgery</h3>
              <p>A wide range of general surgical procedures to address various health concerns.</p>
            </div>
          </Link>
        </div>

        <div className="image-blog">
          <Link to="/blog17">
            <img src={img15} alt="Diabetic Care" className="blog-image" />
            <div className="blog-info">
              <h3>Diabetic Care</h3>
              <p>Comprehensive care and management for patients with diabetes to improve quality of life.</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Home;

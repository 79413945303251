import React from 'react';
import './Gallery.css'; // Import a CSS file for styling

import d1 from './images/Design 11_20240709_153846_0000.png';
import d2 from './images/Design 12_20240709_160001_0000.png';
import d3 from './images/Design 15_20240709_194555_0000.png';
import d12 from './images/Design 1_20240628_170416_0000.png';
import d4 from './images/Design 2_20240628_171108_0000.png';
import d5 from './images/Design 3_20240629_163105_0000.png';
import d6 from './images/Design 4_20240629_161932_0000.png';
import d7 from './images/Design 6_20240630_143938_0000.png';
import d8 from './images/Design 8_20240708_224145_0000 (1).png';
import d9 from './images/Design 13_20240709_163920_0000.png';
import d10 from './images/Design 10_20240709_145444_0000.png';

function Gallery() {
  const images = [d1, d2, d3, d12, d4, d5, d6, d7, d8, d9, d10];

  return (
    
    <div className="gallery">
       
      {images.map((image, index) => (
        <div key={index} className="gallery-item">
          <img src={image} alt={`Design ${index + 1}`} className="gallery-image" />
        </div>
      ))}
    </div>
  );
}

export default Gallery;

import React from 'react';
import './Blog16.css'; // CSS फाइल को स्टाइलिंग के लिए इम्पोर्ट करें
import generalSurgeryImage from './images/genaral.jpeg'; // ब्लॉग 16 के लिए इमेज इम्पोर्ट करें

const Blog16 = () => {
  return (
    <div className="blog16-container">
      <header className="blog16-header">
        <h1>General Surgery</h1>
      </header>
      
      <div className="blog16-content">
        <img src={generalSurgeryImage} alt="General Surgery" className="blog16-image" />
        <div className="blog16-text">
          <p>General surgery encompasses a wide range of surgical procedures to treat various conditions and diseases. These surgeries often involve the abdominal organs, but can also include other areas of the body.</p>
          <p>Our general surgery services include:</p>
          <ul>
            <li>Procedures for digestive disorders, hernias, and appendicitis</li>
            <li>Advanced laparoscopic and minimally invasive techniques</li>
            <li>Personalized care and support throughout the surgical process</li>
          </ul>
          <p>Our experienced team is committed to delivering high-quality care and achieving the best outcomes for our patients.</p>
          <p>For more information or to discuss your surgical options, please contact us.</p>
        </div>
      </div>
    </div>
  );
}

export default Blog16;

import React from 'react';
import './Blog14.css'; // CSS फाइल को स्टाइलिंग के लिए इम्पोर्ट करें
import breastSurgeryImage from './images/breast.jpg'; // ब्लॉग 14 के लिए इमेज इम्पोर्ट करें

const Blog14 = () => {
  return (
    <div className="blog14-container">
      <header className="blog14-header">
        <h1>Breast Surgery</h1>
      </header>
      
      <div className="blog14-content">
        <img src={breastSurgeryImage} alt="Breast Surgery" className="blog14-image" />
        <div className="blog14-text">
          <p>Breast surgery can involve a range of procedures, from biopsies to mastectomies. These surgeries are typically performed to treat breast cancer or other conditions affecting the breast tissue.</p>
          <p>Our breast surgery services include:</p>
          <ul>
            <li>Biopsies and diagnostic procedures</li>
            <li>Partial or total mastectomies</li>
            <li>Reconstructive surgery options</li>
          </ul>
          <p>We provide compassionate care and advanced surgical techniques to ensure the best outcomes for our patients.</p>
          <p>For more information or to discuss your options, please contact us.</p>
        </div>
      </div>
    </div>
  );
}

export default Blog14;

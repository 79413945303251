import React from 'react';
import img7 from './images/Patient-Support.jpg';

const Blog7 = () => {
  return (
    <div className="blog-page">
      <h2 className='blog-sub-image'>Patient Support</h2>
      <img src={img7} alt="Cancer Specialist" className="blog-sub-image" />
      
      <p>
        At our hospital, we understand that cancer treatment involves more than medical interventions. We provide comprehensive support services to address the emotional, psychological, and practical needs of our cancer patients and their families.
      </p>

      <section className="blog-section">
        <h3>Counseling Services</h3>
        <p>
          Coping with cancer can be challenging. Our experienced counselors offer emotional support and guidance to help patients navigate their journey, manage stress, and maintain a positive outlook.
        </p>
      </section>

      <section className="blog-section">
        <h3>Palliative Care</h3>
        <p>
          Palliative care focuses on improving the quality of life for patients facing serious illness, including pain management, symptom control, and holistic support. Our palliative care team collaborates closely with patients and their families to enhance comfort and well-being throughout treatment.
        </p>
      </section>

      <section className="blog-section">
        <h3>Support Groups</h3>
        <p>
          Connecting with others who understand their experiences can be invaluable for cancer patients. We offer support groups where patients can share their challenges, receive encouragement, and build supportive relationships with others facing similar journeys.
        </p>
      </section>

      <section className="blog-section">
        <h3>Financial Guidance</h3>
        <p>
          Dealing with the financial aspects of cancer treatment can add stress. Our team provides financial counseling and guidance to help patients navigate insurance, understand treatment costs, and explore available resources to alleviate financial burdens.
        </p>
      </section>

      <section className="blog-section">
        <h3>Comprehensive Care Coordination</h3>
        <p>
          We take a holistic approach to patient care, coordinating services across medical, emotional, and practical needs. Our care coordination ensures that patients receive integrated and personalized support throughout their cancer journey.
        </p>
      </section>

      <section className="blog-section">
        <h3>Why Choose Us?</h3>
        <p>
          Choosing our hospital means choosing compassionate care and unwavering support for cancer patients. We are committed to enhancing quality of life, fostering resilience, and empowering patients and their families every step of the way.
        </p>
      </section>
    </div>
  );
}

export default Blog7;

import React, { useState } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import './Navrouter.css';

const NavRouter = () => {
  const [expanded, setExpanded] = useState(false);

  const handleNavClose = () => setExpanded(false);

  return (
    <Navbar expand="lg" className={`Navbar ${expanded ? 'expanded' : ''}`} variant="dark" expanded={expanded} onToggle={setExpanded}>
      <Container>
        <Navbar.Brand className='header'>
          <span className="hospital-name">Khushi Hospital</span>
        </Navbar.Brand>
      
        <div className="navbar-submenu">
          <Nav className='nav'>
            <Nav.Link href="#home">
            <Link className='routes' to="/">Home</Link>
           </Nav.Link>

            <Nav.Link href="#kundli">
            <Link className='routes' to="/service">Services</Link>
             </Nav.Link>

            <Nav.Link href="#horoscope">
            <Link className='routes' to='/aboutus' >About Us</Link>
            </Nav.Link>

            <Nav.Link href="#panchang">
            <Link className='routes' to='/gallery'>Gallery</Link>
            </Nav.Link>

            <Nav.Link href="#blogs">
            <Link className='routes' to='/testimonial'>Testimonial</Link>
            </Nav.Link>
            <Nav.Link href="#panchang">
            <Link className='routes' to='/eform'>Contact Us</Link>
            </Nav.Link>
          </Nav>
      </div>

      </Container>
    </Navbar>
  );
}

export default NavRouter;

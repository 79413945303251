import React from 'react';
import img5 from './images/image013.jpg';

const Blog5 = () => {
  return (
    <div className="blog-page">
      <h2 className='blog-sub-image'>Advanced Cancer Treatments</h2>
      <img src={img5} alt="Cancer Specialist" className="blog-sub-image" />
      
      <p>
        At our hospital, we are committed to providing advanced and compassionate care for cancer patients. We offer a range of cutting-edge treatments tailored to each patient's specific type and stage of cancer.
      </p>

      <section className="blog-section">
        <h3>Chemotherapy</h3>
        <p>
          Chemotherapy is a cornerstone of cancer treatment, involving powerful drugs to target and kill cancer cells. We administer chemotherapy in personalized regimens, striving to maximize effectiveness while minimizing side effects for our patients.
        </p>
      </section>

      <section className="blog-section">
        <h3>Radiation Therapy</h3>
        <p>
          Radiation therapy uses high-energy rays or particles to destroy cancer cells and shrink tumors. Our radiation oncologists utilize advanced techniques such as intensity-modulated radiation therapy (IMRT) and image-guided radiation therapy (IGRT) to precisely target cancer while sparing healthy tissue.
        </p>
      </section>

      <section className="blog-section">
        <h3>Surgical Options</h3>
        <p>
          Surgery plays a crucial role in removing tumors and cancerous tissue. Our surgical team specializes in various cancer surgeries, including minimally invasive procedures and complex operations, to achieve optimal outcomes for our patients.
        </p>
      </section>

      <section className="blog-section">
        <h3>Supportive Care</h3>
        <p>
          We understand the physical and emotional challenges faced by cancer patients. In addition to treatments, we offer comprehensive supportive care, including pain management, nutritional counseling, and psychological support to enhance quality of life during and after treatment.
        </p>
      </section>

      <section className="blog-section">
        <h3>Research and Innovation</h3>
        <p>
          Research is at the forefront of our approach to cancer care. Our dedicated research team explores new therapies, clinical trials, and innovations to continuously improve outcomes and offer hope to cancer patients through groundbreaking advancements.
        </p>
      </section>

      <section className="blog-section">
        <h3>Why Choose Us?</h3>
        <p>
          Choosing our hospital for cancer treatment means choosing expertise, innovation, and personalized care. We are committed to fighting cancer with the latest technologies and compassionate support, providing our patients with the best possible chance for recovery and improved quality of life.
        </p>
      </section>
    </div>
  );
}

export default Blog5;

import React from 'react';
import img4 from './images/CANCER 4.avif';
// Ensure this image exists in your project

const Blog4 = () => {
  return (
    <div className="blog-page">
      <h2 className='blog-sub-image'>Women's Health</h2>
      <img src={img4} alt="Women's Health" className="blog-sub-image" />
      
      <p>
        At our hospital, we are dedicated to addressing all aspects of women's health, offering specialized care and treatments tailored to women's unique needs at every stage of life.
      </p>

      <section className="blog-section">
        <h3>Hormonal Treatments</h3>
        <p>
          Hormonal imbalances can affect women's health in various ways. We provide hormonal treatments and therapies to manage conditions such as polycystic ovary syndrome (PCOS), menstrual irregularities, and menopausal symptoms. Our approach focuses on restoring hormonal balance and improving overall well-being.
        </p>
      </section>

      <section className="blog-section">
        <h3>Menopausal Care</h3>
        <p>
          Menopause is a natural phase in a woman's life, but it can bring about significant changes and challenges. We offer comprehensive menopausal care, including symptom management, hormone replacement therapy (HRT), and lifestyle recommendations to help women navigate this transition with comfort and confidence.
        </p>
        
      </section>

      <section className="blog-section">
        <h3>Preventive Health Screenings</h3>
        <p>
          Prevention is key to maintaining women's health. We emphasize regular health screenings and exams, such as mammograms, Pap smears, and bone density tests, to detect potential issues early and provide timely interventions.
        </p>
      </section>

      <section className="blog-section">
        <h3>Comprehensive Care Approach</h3>
        <p>
          Our holistic approach to women's health encompasses physical, emotional, and mental well-being. We collaborate with specialists across disciplines to offer integrated care that addresses the unique needs of each woman, promoting lifelong health and vitality.
        </p>
      </section>

      <section className="blog-section">
        <h3>Why Choose Us?</h3>
        <p>
          Choosing our hospital means choosing expertise, compassion, and personalized care in women's health. We are committed to empowering women with knowledge, supporting their health goals, and providing exceptional medical care in a supportive environment.
        </p>
      </section>
    </div>
  );
}

export default Blog4;

import React from 'react';
import './App.css';
import AboutUs from './AboutUs';
import 'bootstrap/dist/css/bootstrap.min.css'
import Doctorprofile from './Doctorprofile';
import Enquiryform from './Enquiryform';
import Footer from './Footer';
import Home from './Home';
import Services from './Services';
import NavRouter from './NavRouter';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Clienttestimonial from './Clienttestimonial';
import Profile from './Profile';


// Import blog components
import Blog1 from './Blog1';
import Blog2 from './Blog2';
import Blog3 from './Blog3';
import Blog4 from './Blog4';
import Blog5 from './Blog5';
import Blog6 from './Blog6';
import Blog7 from './Blog7';
import Blog8 from './Blog8';
import Blog9 from './Blog9';
import Blog10 from './Blog10';
import Blog11 from './Blog11';
import Blog12 from './Blog12';
import Blog18 from './Blog18';
import Blog13 from './Blog13';
import Blog14 from './Blog14';
import Blog15 from './Blog15';
import Blog16 from './Blog16';
import Blog17 from './Blog17';
import Gallery from './Gallery';


function App() {
  return (
    <div className="App">
       <Profile/>
      <BrowserRouter>
        <NavRouter />
       
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route path="/service" element={<Services />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/eform" element={<Enquiryform />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/testimonial" element={<Clienttestimonial />} />
          
          {/* Add routes for the blog pages */}
          <Route path="/blog1" element={<Blog1 />} />
          <Route path="/blog2" element={<Blog2 />} />
          <Route path="/blog3" element={<Blog3 />} />
          <Route path="/blog4" element={<Blog4 />} />
          <Route path="/blog5" element={<Blog5 />} />
          <Route path="/blog6" element={<Blog6 />} />
          <Route path="/blog7" element={<Blog7 />} />
          <Route path="/blog8" element={<Blog8 />} />
         <Route path="/blog9" element={<Blog9 />} />
         <Route path="/blog10" element={<Blog10 />} />
         <Route path="/blog11" element={<Blog11 />} />
         <Route path="/blog12" element={<Blog12 />} />
         <Route path="/blog13" element={<Blog13 />} />
         <Route path="/blog14" element={<Blog14 />} />
         <Route path="/blog15" element={<Blog15 />} />
         <Route path="/blog16" element={<Blog16 />} />
         <Route path="/blog17" element={<Blog17 />} />
         <Route path="/blog18" element={<Blog18 />} />

        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;

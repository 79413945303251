import React from 'react';
import img6 from './images/Cancer-research.jpg';
import img6_1 from './images/cancer_2000_.jpg'; // Ensure this image exists in your project

const Blog6 = () => {
  return (
    <div className="blog-page">
      <h2 className='blog-sub-image'>Cancer Research</h2>
      <img src={img6} alt="Cancer Specialist" className="blog-sub-image" />
      
      <p>
        At our hospital, cancer research is a cornerstone of our mission. Our dedicated research team works tirelessly to advance understanding and treatment options for cancer patients.
      </p>

      <section className="blog-section">
        <h3>Focus Areas of Research</h3>
        <p>
          Our research covers a broad spectrum of cancer types and treatment modalities. We investigate new therapies, diagnostic methods, and technologies aimed at improving outcomes and quality of life for cancer patients.
        </p>
      </section>

      <section className="blog-section">
        <h3>Clinical Trials</h3>
        <p>
          Participating in clinical trials is integral to our research efforts. These trials evaluate the safety and efficacy of innovative treatments and therapies before they become widely available. By participating in clinical trials, our patients have access to cutting-edge treatments and contribute to advancing medical science.
        </p>
        <img src={img6_1} alt="Clinical Trials" className="blog-sub-image" />
      </section>

      <section className="blog-section">
        <h3>Collaborative Partnerships</h3>
        <p>
          Collaboration is key to successful research outcomes. We collaborate with leading institutions, researchers, and pharmaceutical companies to leverage collective expertise and resources in the fight against cancer. These partnerships enhance our ability to conduct groundbreaking research and bring promising treatments to our patients.
        </p>
      </section>

      <section className="blog-section">
        <h3>Patient-Centered Approach</h3>
        <p>
          Our research endeavors are guided by a patient-centered approach. We prioritize patient needs and perspectives, ensuring that research efforts align with real-world challenges faced by cancer patients. By focusing on patient outcomes and quality of life, we aim to make meaningful advancements in cancer care.
        </p>
      </section>

      <section className="blog-section">
        <h3>Commitment to Excellence</h3>
        <p>
          Excellence drives our research initiatives. We uphold rigorous standards of scientific inquiry and ethical conduct in all aspects of our research. Our commitment to excellence ensures that our findings are robust, reliable, and have the potential to positively impact cancer treatment globally.
        </p>
      </section>

      <section className="blog-section">
        <h3>Why Choose Us?</h3>
        <p>
          Choosing our hospital for cancer research means choosing innovation, expertise, and a commitment to improving outcomes for cancer patients worldwide. We are dedicated to pioneering research that transforms cancer care and offers hope to patients and their families.
        </p>
      </section>
    </div>
  );
}

export default Blog6;
